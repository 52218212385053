import React from 'react';
import ReactDOM from 'react-dom';

const Portal: React.FC = ({ children }) => {
  const portalElement = document.querySelector('#portal');

  if (!portalElement) {
    console.error('No portal element found');
    return null;
  }

  return ReactDOM.createPortal(children, portalElement);
};

export default Portal;
