import type { FixedObject, FluidObject } from 'gatsby-image';
import type {
  PriceId,
  PriceItem,
  Product,
  ProductId,
} from '~/ducks/stripe/types';

export const getLocalFluidImage = (item: Product): FluidObject | undefined => {
  return item.localFiles
    ? item.localFiles[0]?.childImageSharp.fluid
    : undefined;
};

export const getLocalFixedImage = (item: Product): FixedObject | undefined => {
  return item.localFiles
    ? item.localFiles[0]?.childImageSharp.fixed
    : undefined;
};

export const getShippingItemsForCurrency = (
  items: PriceItem[],
  currency: AllowedCurrency
) => {
  return items
    .filter(
      (item) =>
        item.currency === currency &&
        item.product.name.includes('Shipping') &&
        item.product.active
    )
    .sort((a, b) => (a.unit_amount < b.unit_amount ? 1 : -1));
};

export const getPriceItem = (
  prices: PriceItem[],
  id: PriceId,
  currency?: string
) => {
  return prices.find((item) =>
    item.id === id && !currency ? true : item.currency === currency
  );
};

export const getProductPriceItem = (
  prices: PriceItem[],
  id: ProductId,
  currency: string
) => {
  return prices.find(
    (item) => item.product.id === id && item.currency === currency
  );
};
