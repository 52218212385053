import React, { useCallback } from 'react';
import cls from 'classnames';
import style from './button.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  success?: boolean;
}

const Button: React.FC<Props> = ({
  children,
  onClick,
  success,
  ...defaultProps
}) => {
  const handleOnClick = useCallback(
    (e) => {
      if (!defaultProps.disabled && onClick) {
        onClick(e);
      }
    },
    [defaultProps.disabled, onClick]
  );

  return (
    <button
      {...defaultProps}
      onClick={handleOnClick}
      className={cls(style.Button, defaultProps.className, {
        [style.success]: success,
      })}
    >
      {children}
    </button>
  );
};

export default Button;
