import React, { PropsWithChildren, useEffect } from 'react';
import cls from 'classnames';
import Portal from '~/components/common/Portal/Portal';
import style from './Modal.module.scss';

interface MyProps {
  removeBodyOverflow: boolean;
  className?: string;
  temp?: boolean;
  close(): void;
}

type Props = PropsWithChildren<MyProps>;
const Modal = ({ close, ...props }: Props) => {
  useEffect(() => {
    const keyUpListener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        close();
      }
    };

    document.addEventListener('keyup', keyUpListener);

    return () => {
      document.removeEventListener('keyup', keyUpListener);
    };
  }, [close]);

  useEffect(() => {
    if (props.removeBodyOverflow) {
      document.body.classList.add('no-scroll');

      return () => {
        document.body.classList.remove('no-scroll');
      };
    }
  }, [props.removeBodyOverflow]);

  return (
    <Portal>
      <div className={style.Modal}>
        <div className={style.overlay} onClick={close} />
        <div className={cls(style.content, props.className)}>
          {props.children}
        </div>
      </div>
    </Portal>
  );
};

Modal.defaultProps = {
  removeBodyOverflow: true,
};

export default Modal;
