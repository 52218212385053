import Stripe, { RedirectToCheckoutClientOptions } from '@stripe/stripe-js';
import type { StripeLineItem } from '~/ducks/stripe/types';

// SE: 1: 44, 2: 66, 3-4: 88, 5-8: 110
// Utrikes: 1: 66, 2: 90, 3-4: 140, 5-8: 190

const getShippingOptions = (
  currency: AllowedCurrency
): Pick<
  RedirectToCheckoutClientOptions,
  'shippingAddressCollection'
> | null => {
  return currency === 'sek'
    ? {
        shippingAddressCollection: {
          allowedCountries: ['SE'],
        },
      }
    : null;
};

export const redirectToCheckout = async (
  stripe: Stripe.Stripe,
  lineItems: StripeLineItem[],
  currency: AllowedCurrency,
  redirectUrl: SiteMetadata['url']
) => {
  try {
    const response = await stripe.redirectToCheckout({
      lineItems,
      ...getShippingOptions(currency),
      mode: 'payment',
      billingAddressCollection: 'required',
      successUrl: `${redirectUrl}/success?session={CHECKOUT_SESSION_ID}`,
      cancelUrl: `${redirectUrl}/shop`,
    });

    if (response.error) {
      throw response.error;
    }
  } catch (error) {
    console.error(
      'Something went wrong when trying to redirect to checkout',
      error
    );

    return error;
  }
};
