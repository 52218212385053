import React, { useCallback } from 'react';
import style from './QuantitySpinner.module.scss';

export interface Props {
  quantity: number;
  displayQuantityWord: boolean;
  minValue: number;
  setQuantity(quantiy: number): void;
}

const getNumberValues = (minNumber: number, maxNum: number) => {
  const numbers: number[] = [];
  for (let i = minNumber; i <= 8; i++) {
    numbers.push(i);
  }
  return numbers;

  // return Array(Math.max(maxNum, 8))
  //   .fill('')
  //   .map((_i, index) => index + 1);
};

const QuantitySpinner = ({
  minValue,
  quantity,
  displayQuantityWord,
  setQuantity,
}: Props) => {
  const numberValues = getNumberValues(minValue, quantity);

  const handleOnChange = useCallback(
    (event) => {
      setQuantity(Number(event.target.value));
    },
    [setQuantity]
  );

  return (
    <div className={style.QuantitySpinner}>
      {displayQuantityWord && <span className="mr-2">Quantity:</span>}
      <select value={quantity} onChange={handleOnChange}>
        {numberValues.map((val) => (
          <option key={val} value={val}>
            {val}
          </option>
        ))}
      </select>
    </div>
  );
};

QuantitySpinner.defaultProps = {
  minValue: 0,
};

export default QuantitySpinner;
