import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import type { PriceItem, Product } from '~/ducks/stripe/types';

export type StripeDataType = {
  prices: PriceItem[];
  products: Product[];
};

const useStripeData = (): StripeDataType => {
  const { allStripePrice, allStripeProduct } = useStaticQuery(graphql`
    query {
      allStripePrice(
        filter: { active: { eq: true }, product: { active: { eq: true } } }
      ) {
        totalCount
        edges {
          node {
            id
            nickname
            created
            unit_amount
            unit_amount_decimal
            currency
            product {
              id
              name
              description
              active
              updated
              metadata {
                weight
              }
            }
          }
        }
      }
      allStripeProduct(
        filter: { active: { eq: true }, metadata: { shipping: { ne: "1" } } }
        sort: { fields: [name, description], order: ASC }
      ) {
        totalCount
        edges {
          node {
            id
            name
            description
            shippable
            active
            updated
            metadata {
              weight
            }
            localFiles {
              childImageSharp {
                fixed(width: 218) {
                  originalName
                  ...GatsbyImageSharpFixed
                }
                fluid {
                  originalName
                  presentationWidth
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const state = useMemo<StripeDataType>(
    () => ({
      prices: allStripePrice.edges.map((edge) => edge.node),
      products: allStripeProduct.edges.map((edge) => edge.node),
    }),
    [allStripeProduct, allStripePrice]
  );

  return state;
};

export default useStripeData;
