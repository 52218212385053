import type { CartItem } from '~/ducks/shopping/shopping-context';

const hasWindow = typeof window !== 'undefined';

export const getCart = (): CartItem[] => {
  if (hasWindow) {
    const string = window.localStorage.getItem('cart') || '[]';
    return JSON.parse(string);
  }

  return [];
};

export const setCart = (cartItems: CartItem[]): void => {
  if (hasWindow) {
    window.localStorage.setItem('cart', JSON.stringify(cartItems));
  }
};

export const getCurrency = (
  defaultCurrency: AllowedCurrency = 'usd'
): AllowedCurrency => {
  if (hasWindow) {
    const string = window.localStorage.getItem(
      'currency'
    ) as AllowedCurrency | null;
    return string || defaultCurrency;
  }

  return defaultCurrency;
};

export const setCurrency = (currency: AllowedCurrency): void => {
  if (hasWindow) {
    window.localStorage.setItem('currency', currency);
  }
};
