import cls from 'classnames';
import React, { useCallback, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useCartContext } from '~/ducks/shopping/shopping-context';
import {
  getFormattedPrice,
  getLineItemsForTotalOrder,
} from '~/ducks/shopping/shopping-utils';
import * as shoppingUtils from '~/ducks/shopping/shopping-utils';
import { useStripeDataContext } from '~/ducks/stripe/stripe-context';
import * as stripeUtils from '~/ducks/stripe/stripe-utils';
import { useSiteMetadata } from '~/hooks/use-metadata-hooks';
import Modal from '~/components/common/Modal/Modal';
import Button from '~/components/input/button/button';
import CartListItem from './components/CartListItem/CartListItem';
import LargeOrderMessage from './components/LargeOrderMessage/LargeOrderMessage';
import style from './CartModal.module.scss';

const CartModal = () => {
  const stripe = useStripe();
  const siteMetaData = useSiteMetadata();
  const {
    shoppingCart,
    removeAllItems,
    setQuantity,
    hasCartItems,
    toggleCartModalIsOpen,
    chosenCurrency,
  } = useCartContext();
  const { prices, selectors } = useStripeDataContext();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const checkoutIsDisabled = !hasCartItems() || isRedirecting;

  const shippingItems = shoppingUtils.getShippingPricesItemForCart(
    shoppingCart,
    prices,
    chosenCurrency
  );
  const shippingProductName = shippingItems[0]?.priceItem.product.name;

  const shippingMoreThanOnePackage =
    shippingItems.reduce((acc, curr) => {
      return acc + curr.quantity;
    }, 0) > 1;

  const shippingTotalPrice = shippingItems.reduce((acc, curr) => {
    return acc + curr.totalPrice;
  }, 0);

  const formattedTotalCartPrice = shoppingUtils.getFormattedTotalCartPrice(
    shoppingCart,
    shippingTotalPrice,
    prices,
    chosenCurrency
  );

  const onOrderClick = useCallback(async () => {
    if (!stripe) {
      return;
    }

    setIsRedirecting(true);

    const lineItems = getLineItemsForTotalOrder(
      prices,
      shoppingCart,
      shippingItems,
      chosenCurrency
    );

    const error = await stripeUtils.redirectToCheckout(
      stripe,
      lineItems,
      chosenCurrency,
      siteMetaData.url
    );

    if (error) {
      // Todo: Show error somewhere
    }

    setIsRedirecting(false);
  }, [
    stripe,
    prices,
    siteMetaData.url,
    chosenCurrency,
    shoppingCart,
    shippingItems,
  ]);

  const hasItems = hasCartItems();

  return (
    <Modal className={style.CartModal} close={toggleCartModalIsOpen}>
      {!hasItems ? (
        <h5 className={style.cartEmptyMessage}>The shopping cart is empty</h5>
      ) : (
        <ul className={style.cartItems}>
          {shoppingCart.map((cartItem) => {
            const priceItem = selectors.getProductPriceItem(
              cartItem.product.id,
              chosenCurrency
            );

            return (
              <CartListItem
                key={cartItem.product.id}
                product={cartItem.product}
                priceItem={priceItem}
                quantity={cartItem.quantity}
                setQuantity={setQuantity}
                onRemoveClick={removeAllItems}
              />
            );
          })}
        </ul>
      )}

      <div className={style.bottom}>
        {hasItems && (
          <div className={style.priceSummary}>
            <div className={style.grid}>
              {shippingTotalPrice > 0 && shippingProductName && (
                <>
                  <span className={style.shippingPrice}>
                    {shippingProductName}:
                  </span>
                  <span className={cls(style.shippingPrice, 'bold')}>
                    {getFormattedPrice(shippingTotalPrice, chosenCurrency)}
                  </span>
                </>
              )}

              <>
                <span className={style.total}>Total:</span>
                <span className={cls(style.total, 'bold')}>
                  {formattedTotalCartPrice}
                </span>
              </>
            </div>
          </div>
        )}
        <div className={style.buttons}>
          <Button onClick={onOrderClick} disabled={checkoutIsDisabled}>
            {isRedirecting ? (
              <span>Redirecting to checkout</span>
            ) : (
              <span>Continue to checkout</span>
            )}
          </Button>
          <Button onClick={toggleCartModalIsOpen}>Close</Button>
        </div>
        {shippingMoreThanOnePackage && (
          <LargeOrderMessage email={siteMetaData.email.order} />
        )}
      </div>
    </Modal>
  );
};

export default CartModal;
