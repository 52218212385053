import React, { useCallback } from 'react';
import Img from 'gatsby-image';
import type { PriceItem, Product, ProductId } from '~/ducks/stripe/types';
import QuantitySpinner from '~/components/pages/shop/QuantitySpinner/QuantitySpinner';
import { getLocalFluidImage } from '~/ducks/stripe/selectors';
import { getFormattedPrice } from '~/ducks/shopping/shopping-utils';
import style from './CartListItem.module.scss';

export interface Props {
  product: Product;
  priceItem: PriceItem;
  quantity: number;
  setQuantity(productId: ProductId, quantity: number): void;
  onRemoveClick(productId: ProductId): void;
}

const CartListItem = ({
  product,
  priceItem,
  quantity,
  setQuantity,
  onRemoveClick,
}: Props) => {
  const localFluidImage = getLocalFluidImage(product);

  const removeCartItem = useCallback(() => {
    onRemoveClick(product.id);
  }, [product.id, onRemoveClick]);

  const handleQuantityChange = useCallback(
    (quantity: number) => {
      setQuantity(product.id, quantity);
    },
    [setQuantity, product.id]
  );

  return (
    <li className={style.CartListItem}>
      <div className={style.imageWrapper}>
        <Img fluid={localFluidImage} />
      </div>

      <div className={style.itemInfoWrapper}>
        <div className={style.itemInfoInner}>
          <div>
            <div className={style.productName}>{product.name}</div>
            <span>{product.description}</span>
          </div>
        </div>
      </div>
      <div className={style.quantity}>
        <div className={style.controls}>
          <span onClick={removeCartItem}>🗑</span>
        </div>
        <QuantitySpinner
          minValue={1}
          displayQuantityWord={true}
          quantity={quantity}
          setQuantity={handleQuantityChange}
        />
        <span className="bold">
          {getFormattedPrice(
            priceItem.unit_amount * quantity,
            priceItem.currency
          )}
        </span>
      </div>
    </li>
  );
};

export default CartListItem;
