// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-characters-tsx": () => import("./../../../src/pages/characters.tsx" /* webpackChunkName: "component---src-pages-characters-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-synopsis-tsx": () => import("./../../../src/pages/synopsis.tsx" /* webpackChunkName: "component---src-pages-synopsis-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-trailers-tsx": () => import("./../../../src/pages/trailers.tsx" /* webpackChunkName: "component---src-pages-trailers-tsx" */)
}

