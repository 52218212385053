import type { CartItem } from '~/ducks/shopping/shopping-context';
import {
  getPriceItem,
  getProductPriceItem,
  getShippingItemsForCurrency,
} from '~/ducks/stripe/selectors';
import type { PriceItem, StripeLineItem } from '~/ducks/stripe/types';
import { getNavigatorLanguages } from '~/utils/get-navigator-languages';

export const getTotalPrice = (
  cartItems: CartItem[],
  prices: PriceItem[],
  currency
) => {
  return cartItems.reduce((acc, cartItem) => {
    const priceItem = getProductPriceItem(
      prices,
      cartItem.product.id,
      currency
    );

    if (!priceItem) {
      return acc;
    }

    return acc + priceItem.unit_amount * cartItem.quantity;
  }, 0);
};

export const getCartTotalWeight = (cartItems: CartItem[]) => {
  return cartItems.reduce((acc, curr) => {
    const productWeight = Number(curr.product.metadata.weight);

    if (isNaN(productWeight)) {
      console.error('Metadata.weight is not set on product', {
        curr,
      });
      return acc;
    }

    return acc + productWeight * curr.quantity;
  }, 0);
};

export const getFormattedPrice = (price: number, currency: string) => {
  const smallerPrice = Number((price / 100).toFixed(2));

  const numberFormat = new Intl.NumberFormat(getNavigatorLanguages(), {
    currency,
    style: 'currency',
    currencyDisplay: 'symbol',
  });

  return numberFormat.format(smallerPrice);
};

export const getFormattedTotalCartPrice = (
  shoppingCart: CartItem[],
  shippingTotalPrice: number,
  prices: PriceItem[],
  chosenCurrency: AllowedCurrency
) => {
  const cartTotalPrice = getTotalPrice(shoppingCart, prices, chosenCurrency);
  const cartTotalWithShipping = cartTotalPrice + shippingTotalPrice;
  return getFormattedPrice(cartTotalWithShipping, chosenCurrency);
};

export const getLowestShippingPriceForWeight = (
  weight: number,
  shipping: PriceItem[]
): PriceItem | undefined => {
  const validItems = shipping
    .filter((priceItem) => Number(priceItem.nickname) >= weight)
    .sort((a, b) => (a.unit_amount < b.unit_amount ? -1 : 1));

  return validItems[0];
};

export const getShippingPrices = (
  weight: number,
  shippingForCurrency: PriceItem[]
): StripeLineItem[] => {
  const lineItems: StripeLineItem[] = [];
  if (weight <= 0) {
    return lineItems;
  }

  let calculatedWeight = weight;

  const [biggestPackage] = shippingForCurrency;
  const biggestPackageWeight = Number(biggestPackage.nickname);
  const nrBigPackages = Math.floor(calculatedWeight / biggestPackageWeight);

  if (nrBigPackages > 0) {
    lineItems.push({
      price: biggestPackage.id,
      quantity: nrBigPackages,
    });

    calculatedWeight -= biggestPackageWeight * nrBigPackages;
  }

  if (calculatedWeight === 0) {
    return lineItems;
  }

  const lowest = getLowestShippingPriceForWeight(
    calculatedWeight,
    shippingForCurrency
  );

  if (!lowest) {
    return lineItems;
  }

  const existingIndex = lineItems.findIndex(
    (item) => item.price === lowest!.id
  );

  if (existingIndex !== -1) {
    lineItems[existingIndex].quantity += 1;
  } else {
    lineItems.push({
      price: lowest!.id,
      quantity: 1,
    });
  }

  return lineItems;
};

// SE: 1: 44, 2: 66, 3-4: 88, 5-8: 110
// Utrikes: 1: 66, 2: 90, 3-4: 140, 5-8: 190

export const getShippingPriceItemForCart = (
  cartItems: CartItem[],
  shippingPriceItems: PriceItem[],
  chosenCurrency: AllowedCurrency
): PriceItem | undefined => {
  const totalCartWeight = getCartTotalWeight(cartItems);

  if (totalCartWeight === 0) {
    return;
  }

  const shippingForCurrency = getShippingItemsForCurrency(
    shippingPriceItems,
    chosenCurrency
  );

  return getLowestShippingPriceForWeight(totalCartWeight, shippingForCurrency);
};

export interface CombinedStuff extends StripeLineItem {
  totalPrice: number;
  priceItem: PriceItem;
}

export const getShippingPricesItemForCart = (
  cartItems: CartItem[],
  shippingPriceItems: PriceItem[],
  chosenCurrency: AllowedCurrency
): Array<CombinedStuff> => {
  const totalCartWeight = getCartTotalWeight(cartItems);

  if (totalCartWeight === 0) {
    return [];
  }

  const shippingForCurrency = getShippingItemsForCurrency(
    shippingPriceItems,
    chosenCurrency
  );

  const shippingLineItems = getShippingPrices(
    totalCartWeight,
    shippingForCurrency
  );

  return shippingLineItems.map((item) => {
    const priceItem = getPriceItem(
      shippingForCurrency,
      item.price
    ) as PriceItem;
    return {
      ...item,
      priceItem,
      totalPrice: priceItem.unit_amount * item.quantity,
    };
  });
};

export const getLineItemsForTotalOrder = (
  prices: PriceItem[],
  shoppingCart: CartItem[],
  shippingItems: CombinedStuff[],
  chosenCurrency: AllowedCurrency
): StripeLineItem[] => {
  let lineItems = shoppingCart.map((item) => {
    const priceItem = getProductPriceItem(
      prices,
      item.product.id,
      chosenCurrency
    ) as PriceItem;

    return {
      price: priceItem.id,
      quantity: item.quantity,
    };
  });

  if (shippingItems) {
    lineItems = lineItems.concat(
      shippingItems.map((shippingItem) => ({
        quantity: shippingItem.quantity,
        price: shippingItem.price,
      }))
    );
  }

  return lineItems;
};
