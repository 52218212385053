import { graphql, useStaticQuery } from 'gatsby';

export const useSiteMetadata = (): SiteMetadata => {
  const {
    site: { siteMetadata },
  } = useStaticQuery<SiteGraphqlResult>(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            email {
              info
              order
              personal
            }
            url
            socialMedia {
              facebook
              instagram
              personalInstagram
              twitter
              soundcloud
            }
            team {
              role
              name
              website
            }
            stripe {
              publicKey
            }
          }
        }
      }
    `
  );

  return siteMetadata;
};

export const useSocialMedia = (): SocialMediaUrls => {
  const { socialMedia } = useSiteMetadata();
  return socialMedia;
};
