import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useImmer } from 'use-immer';
import type { Product, ProductId } from '~/ducks/stripe/types';
import CartModal from '~/components/pages/shop/CartModal/CartModal';
import * as localStorageUtil from '~/utils/local-storage';

export interface CartItem {
  product: Product;
  quantity: number;
}

export type CartModalState = [boolean, () => void];

export interface CartState {
  shoppingCart: CartItem[];
  chosenCurrency: AllowedCurrency;
  cartModalIsOpen: boolean;
  setChosenCurrency(cb: () => AllowedCurrency): void;
  addItem(product: Product): void;
  removeItem(productId: ProductId, deleteLast?: boolean): void;
  removeAllItems(productId: ProductId): void;
  clearCart(): void;
  setQuantity(productId: ProductId, quantity: number): void;
  findCartItem(productId: ProductId): CartItem | undefined;
  hasCartItems(): boolean;
  toggleCartModalIsOpen(): void;
  getTotalNumberOfItems(): number;
}

const CartContext = createContext<CartState | undefined>(undefined);

export const useCartContext = (): CartState => {
  const context = useContext(CartContext);
  if (!context) {
    throw new ReferenceError('CartContext is not defined');
  }
  return context;
};

const useShoppingCart = (
  initialCurrency: AllowedCurrency,
  initialValue: CartItem[] = []
): CartState => {
  const [shoppingCart, setShoppingCart] = useImmer<CartItem[]>(initialValue);
  const [cartModalIsOpen, toggleCartModalIsOpen] = useIsModalOpen();
  const [chosenCurrency, setChosenCurrency] = useImmer<AllowedCurrency>(
    initialCurrency
  );

  const findCartItem = useCallback(
    (productId: ProductId) => {
      return shoppingCart.find((cartItem) => cartItem.product.id === productId);
    },
    [shoppingCart]
  );

  const addItem = useCallback(
    (product: Product) => {
      setShoppingCart((draft) => {
        const currItem = findCartItem(product.id);

        if (currItem) {
          currItem.quantity += 1;
        } else {
          draft.push({
            product,
            quantity: 1,
          });
        }

        return draft;
      });
    },
    [setShoppingCart, findCartItem]
  );

  const removeItem = useCallback(
    (productId: ProductId, deleteLast = true) => {
      setShoppingCart((draft) => {
        const currItem = findCartItem(productId);
        if (!currItem) {
          return draft;
        }

        if (currItem.quantity === 1) {
          if (deleteLast) {
            return draft.filter((s) => s.product.id !== productId);
          }
        } else {
          currItem.quantity -= 1;
        }

        return draft;
      });
    },
    [setShoppingCart, findCartItem]
  );

  const removeAllItems = useCallback(
    (productId: ProductId) => {
      setShoppingCart((draft) => {
        return draft.filter((cartItem) => cartItem.product.id !== productId);
      });
    },
    [setShoppingCart]
  );

  const clearCart = useCallback(() => {
    setShoppingCart(() => []);
  }, [setShoppingCart]);

  const setQuantity = useCallback(
    (productId: ProductId, quantity: number) => {
      if (quantity <= 0) {
        removeAllItems(productId);
        return;
      }
      setShoppingCart((draft) => {
        const currItem = draft.find((d) => d.product.id === productId);

        if (!currItem) {
          return draft;
        }

        currItem.quantity = quantity;
        return draft;
      });
    },
    [setShoppingCart, removeAllItems]
  );

  const hasCartItems = useCallback(() => {
    return shoppingCart.length > 0;
  }, [shoppingCart]);

  const getTotalNumberOfItems = useCallback(() => {
    return shoppingCart.reduce((acc, curr) => {
      acc += curr.quantity;
      return acc;
    }, 0);
  }, [shoppingCart]);

  return useMemo(
    () => ({
      chosenCurrency,
      setChosenCurrency,
      shoppingCart,
      addItem,
      removeItem,
      removeAllItems,
      clearCart,
      setQuantity,
      hasCartItems,
      findCartItem,
      cartModalIsOpen,
      toggleCartModalIsOpen,
      getTotalNumberOfItems,
    }),
    [
      chosenCurrency,
      setChosenCurrency,
      shoppingCart,
      addItem,
      removeItem,
      removeAllItems,
      clearCart,
      setQuantity,
      hasCartItems,
      findCartItem,
      cartModalIsOpen,
      toggleCartModalIsOpen,
      getTotalNumberOfItems,
    ]
  );
};

const useIsModalOpen = (): CartModalState => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const toggleModalOpen = useCallback(() => {
    setIsModalOpen((isOpen) => !isOpen);
  }, [setIsModalOpen]);

  return useMemo(() => [isModalOpen, toggleModalOpen], [
    isModalOpen,
    toggleModalOpen,
  ]);
};

export const ShoppingCartContextProvider: React.FC = ({ children }) => {
  const cartState = useShoppingCart(
    localStorageUtil.getCurrency('usd'),
    localStorageUtil.getCart()
  );

  useEffect(() => {
    localStorageUtil.setCart(cartState.shoppingCart);
  }, [cartState.shoppingCart]);

  useEffect(() => {
    localStorageUtil.setCurrency(cartState.chosenCurrency);
  }, [cartState.chosenCurrency]);

  return (
    <CartContext.Provider value={cartState}>
      {cartState.cartModalIsOpen && <CartModal />}
      {children}
    </CartContext.Provider>
  );
};
