import React, { useCallback, useContext, useMemo } from 'react';
import type {
  PriceItem,
  Product,
  ProductId,
} from '~/ducks/stripe/types';
import useStripeData from '~/ducks/stripe/use-stripe-data';

export type StripeContextType = {
  products: Product[];
  prices: PriceItem[];
  selectors: {
    getProduct(productId: ProductId): Product;
    getProductPrices(productId: ProductId): PriceItem[];
    getProductPriceItem(productId: ProductId, currency: string): PriceItem;
  };
};

const StripeDataContext = React.createContext<StripeContextType | undefined>(
  undefined
);

export const useStripeDataContext = (): StripeContextType => {
  const context = useContext(StripeDataContext);
  if (!context) {
    throw new Error('StripeDataContext not defined');
  }

  return context;
};

export const StripeDataContextProvider: React.FC = (props) => {
  const { products, prices } = useStripeData();

  const getProduct = useCallback(
    (productId: ProductId): Product => {
      const item = products.find((item) => item.id === productId);
      if (!item) {
        throw new ReferenceError('Could not find any currencies');
      }

      return item;
    },
    [products]
  );

  const getProductPrices = useCallback(
    (productId: ProductId): PriceItem[] => {
      const productPrices = prices.filter((f) => f.product.id === productId);
      if (productPrices.length === 0) {
        throw new ReferenceError('Could not find price items for product');
      }

      return productPrices;
    },
    [prices]
  );

  const getProductPriceItem = useCallback(
    (productId: ProductId, currency: string): PriceItem => {
      const productPriceItem = prices.find(
        (f) => f.product.id === productId && f.currency === currency
      );

      if (!productPriceItem) {
        throw new ReferenceError(
          'Could not find price item for product and currency'
        );
      }

      return productPriceItem;
    },
    [prices]
  );

  const selectors: StripeContextType['selectors'] = useMemo(
    () => ({
      getProduct,
      getProductPrices,
      getProductPriceItem,
    }),
    [getProduct, getProductPrices, getProductPriceItem]
  );

  const state: StripeContextType = useMemo(
    () => ({
      prices,
      products,
      selectors,
    }),
    [prices, products, selectors]
  );

  return (
    <StripeDataContext.Provider value={state}>
      {props.children}
    </StripeDataContext.Provider>
  );
};
