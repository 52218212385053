import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ShoppingCartContextProvider } from './src/ducks/shopping/shopping-context';
import { StripeDataContextProvider } from './src/ducks/stripe/stripe-context';
import { useSiteMetadata } from './src/hooks/use-metadata-hooks';

export const WrapWithProviders = ({ element }) => {
  const metadata = useSiteMetadata();
  const stripePromise = loadStripe(metadata.stripe.publicKey);

  return (
    <Elements stripe={stripePromise}>
      <StripeDataContextProvider value={{}}>
        <ShoppingCartContextProvider>{element}</ShoppingCartContextProvider>
      </StripeDataContextProvider>
    </Elements>
  );
};
