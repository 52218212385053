import React from 'react';
import style from '../../CartModal.module.scss';

export interface Props {
  email: string;
}

const LargeOrderMessage = ({ email }: Props) => {
  return (
    <span className={style.largeOrderMessage}>
      Please contact
      <a href={`mailto:${email}?subject=Large order`}>{email}</a>
      to get lower shipping costs for larger orders.
    </span>
  );
};

export default LargeOrderMessage;
